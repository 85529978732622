import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import { message } from "antd";
import axios from "axios";

function AboutUs() {
  const [data, setData] = useState("");

  const getData = async () => {
    try {
      const res = await axios.get("page/about");
      setData(res.data.response?.content);
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <Navbar text={"About Us"} />
      <div className="flex justify-center text-sm font-medium p-10 mt-10 bg-white rounded-3xl w-[80%] mx-auto">
      <div dangerouslySetInnerHTML={{ __html: data }} className="flex flex-col gap-5" />
      </div>
    </div>
  );
}

export default AboutUs;
