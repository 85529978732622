import React, { useState } from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import { Button, DatePicker, Form, Input, message } from "antd";
import LabelForInput from "../../../Components/Inputs/LabelForInput/LabelForInput";
import LockIcon from "../../../Assets/CardDetails/Icon awesome-lock.png";
import creditCard from "../../../Assets/CardDetails/credit-card.png";
import CommonButton from "../../../Components/Button/Button";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { CARD_DETAILS, MANAGE_CARD, PROFILE } from "../../../Constants/Router/RouterConstant";

function AddCard() {
  const [form] = Form.useForm();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const  location  = useLocation();

  const navigate = useNavigate();

  const submitPaymentMethod = async (paymentMethod) => {
    try {
      const res = await axios.post(`sa_add_card`, {
        token: paymentMethod,
      });
      message.success("Card Added Successfully");
      navigate(PROFILE + MANAGE_CARD);
    } catch (err) {
      console.log(err);
    }
  };

  const onFinish = async (values) => {
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    const cardElement = elements.getElement(CardNumberElement);

    try {
      const res = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: values.name,
        },
      });
      if (res.error) {
        message.error(res.error.message);
      } else {
        submitPaymentMethod(res.paymentMethod.id);
      }
    } catch (err) {
      message.error("Payment Failed");
    }

    setLoading(false);
    //   console.log(paymentMethod, error)
  };
  return (
    <>
      <Navbar text={"Card Details"} />
      <div className="flex justify-center items-center pt-2 w-80 m-auto flex-col gap-2">
        <div className="text-secondary font-bold text-xs text-center flex justify-center items-center flex-col">
          <img src={creditCard} alt="credit card" className="mb-4" />
          <div>This is only for verification, you would not </div>
          <div>be charged before creating any job.</div>
        </div>
        <Form form={form} onFinish={onFinish}>
          <div className="w-full">
            <LabelForInput text="Name On Card" />
            <Form.Item
              name={"name"}
              rules={[
                {
                  required: true,
                  message: "Please Enter Name",
                },
              ]}
            >
              <Input className="h-12 w-full" placeholder="Enter Name" />
            </Form.Item>
          </div>
          <div className="my-3">
            <LabelForInput text="Enter Card Number" />
            <div
              className="w-full h-12 "
              style={{
                border: `1px solid #E5E5E5`,
                background: `#Fff`,
                padding: `20px 10px 10px 10px`,
                fontFamily: "Nunito, sans-serif",
              }}
            >
              <CardNumberElement
                options={{
                  style: {
                    base: inputStyle,
                  },
                }}
              />
            </div>
          </div>
          <div className="flex gap-2 w-full mb-3">
            <div className="w-[48%]">
              <LabelForInput text="Enter Card Number" />
              <div
                className="w-full h-12 "
                style={{
                  border: `1px solid #E5E5E5`,
                  background: `#Fff`,
                  padding: `20px 10px 10px 10px`,
                }}
              >
                <CardCvcElement
                  options={{
                    style: {
                      base: inputStyle,
                    },
                  }}
                />
              </div>
            </div>
            <div className="w-[48%]">
              <LabelForInput text="Enter Card Number" />
              <div
                className="w-full h-12 "
                style={{
                  border: `1px solid #E5E5E5`,
                  background: `#Fff`,
                  padding: `20px 10px 10px 10px`,
                }}
              >
                <CardExpiryElement
                  options={{
                    style: {
                      base: inputStyle,
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mb-8">
            <div className="flex items-center gap-2 font-normal text-xs">
              <span>
                <img src={LockIcon} />
              </span>
              Your details are secured
            </div>
          </div>
          <Form.Item>
            <Button
              loading={loading}
              className="h-12 w-full"
              htmlType="submit"
              style={{
                background: `#0071BD`,
                borderRadius: `5px`,
                border: `none`,
                color: `#fff`,
                fontFamily: `Nunito, sans-serif`,
                fontWeight: `bold`,
                fontSize: `16px`,
                lineHeight: `24px`,
                letterSpacing: `0.02em`,
                textTransform: `uppercase`,
                height: `48px`,
                width: `400px`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
                cursor: `pointer`,
                padding: `0px`,
                margin: `0px`,
                boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
              }}
              type="primary"
            >
              Submit
            </Button>
           {location.pathname===CARD_DETAILS && <Button onClick={()=>{
            window.location.reload()
           }} type="primary" ghost className="h-12 w-full mt-4">
              Skip
            </Button>
}
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default AddCard;

const inputStyle = {
  "::placeholder": {
    color: "#e5e5e5",
  },
};
