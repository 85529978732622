import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

function CommonButton({ color, text, link,type }) {
  const navigate = useNavigate();

  const clickHandle = () => {
    navigate(link);
  };

  return (
    <>
      <Button
        className={`${color} font-bold text-lg text-white w-80 h-12 rounded-md`}
        type=""
        onClick={clickHandle}
        htmlType={type}
      >
        {text}
      </Button>
    </>
  );
}

export default CommonButton;
