import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import { Firebasedb } from "../../../Service/Firebase";
import { useSelector } from "react-redux";
import { Button, Card, Input } from "antd";
import { LoadingOutlined, SendOutlined } from "@ant-design/icons";
import { chatMsgNotification } from "../../../Service/pushNotification";

function ChatSupport() {
  const bottom = useRef(null);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const firebaseRef = useRef(null);

  const userData = useSelector(
    (state) => state?.verifySlice?.data?.response?.user
  );

  useEffect(() => {
    if (!userData?.support_token) {
      return;
    }
    firebaseRef.current = Firebasedb.ref(
      "/user_chats/" + userData?.support_token
    );

    firebaseRef.current.on("value", (snap) => {
      if (snap.numChildren() === 0) {
        setLoading(false);
      }
    });

    firebaseRef.current.on("child_added", (dataSnapshot) => {
      setLoading(false);
      if (dataSnapshot !== undefined) {
        onReceive(parseMsg(dataSnapshot));
      }
    });

    return () => {
      firebaseRef.current.off();
    };
  }, [userData]);

  useEffect(() => {
    if (bottom.current) {
      bottom.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [messages]);

  const onSend = (message = []) => {
    console.log(message);
    console.log(messages);
    setText("");
    let adminCount = 0;
    let userCount = messages.length > 0 ? messages[0]?.userCount + 1 : 1;
    let tempMsg = [];
    var phoneExp =
      /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/gim;
    if (phoneExp.test(message[0].text)) {
      for (let i = 0; i < message.length; i++) {
        let msgObj = {};
        msgObj = message[i];
        const { text, user } = message[i];
        var words = text.split(" ");
        let new_message = "";
        for (var j = 0; j < words.length; j++) {
          var phoneExp1 =
            /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/gim;
          if (phoneExp1.test(words[j])) {
            let masked = "";
            for (var k = 0; k < words[j].length; k++) {
              if (k <= words[j].length - 3) {
                masked += "X";
              } else {
                masked += words[j][k];
              }
            }
            new_message += " " + masked;
          } else {
            new_message += " " + words[j];
          }
        }
        msgObj["text"] = new_message;
        tempMsg.push(msgObj);

        const message = {
          text: new_message,
          user,
          createdAt: new Date().getTime(),
          adminCount,
          userCount,
        };
        firebaseRef.current.push(message);
        // console.log('tempMsg', tempMsg);
       sendNotification(tempMsg);
      }
    } else {
      for (let i = 0; i < message.length; i++) {
        const { text, user } = message[i];
        const mes = {
          text,
          user,
          createdAt: new Date().getTime(),
          adminCount,
          userCount,
        };
        firebaseRef.current.push(mes);
        sendNotification(messages);
      }
    }
  };

  const onReceive = (data) => {
    setMessages((prev) => [...prev, data]);
  };

  const sendNotification = (data) => {
    var msgData = {
      to: "admin",
      chat_id: userData?.support_token,
      type: "admin_chat",
      notification: data,
    };
    chatMsgNotification(msgData);
  };

  const parseMsg = (snapshot) => {
    const data = snapshot.val();
    return {
      text: data.text,
      adminCount: data.adminCount,
      userCount: data.userCount,
      user: data.user,
    };
  };

  return (
    <div>
      <Navbar text={"Chat Support"} />
      <Card className="w-[80%] my-4 h-[33vw] overflow-auto mx-auto">
        {loading && (
          <div className="w-full h-full flex justify-center items-center">
            <LoadingOutlined />
          </div>
        )}
        {
          <div className="flex flex-col justify-end  gap-2">
            <>
              {messages.map((msg, index) =>
                msg.adminCount === 1 ? (
                  <div key={index} className="flex justify-start items-center">
                    <div className=" text-[#000] bg-[#e0e0e0] p-2 rounded-lg">
                      {msg.text}
                    </div>
                  </div>
                ) : (
                  <div key={index} className="flex justify-end items-center">
                    <div className="bg-secondary text-white p-2 rounded-lg">
                      {msg.text}
                    </div>
                  </div>
                )
              )}
            </>

            <div ref={bottom}></div>
          </div>
        }
      </Card>
      <div className="flex m-auto w-[80%]">
        <Input
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          className="h-12"
        />
        <Button
          type="primary"
          className="bg-secondary h-12"
          onClick={() => {
            if (text === "") {
              return;
            }
            onSend([
              {
                text: text,
                adminCount: 0,
                user: {
                  _id: userData?.email,
                  name: userData?.name,
                  avatar: userData?.avatar,
                },
              },
            ]);
          }}
        >
          <SendOutlined />
        </Button>
      </div>
    </div>
  );
}

export default ChatSupport;
