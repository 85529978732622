import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import Tabs from "../../../Layouts/AppLayout/Tabs/Tabs";
import { Empty, message } from "antd";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import DisputeContainer from "./DisputeContainer/DisputeContainer";

function Dispute() {
  const [active, setActive] = useState(0);
  const options = ["Active", "Resolved", "Total"];
  const [disputeData, setDisputeData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDisputeData = async (index) => {
    setLoading(true);
    try {
      const res = await axios.post("disputes", {
        dispute_type: index,
      });
      setDisputeData(res?.data?.data);
      setLoading(false);
    } catch (err) {
      message.error("Something went wrong");
      setLoading(false);
    }
  };

  const onChange = (index) => {
    setActive(index);
    if (index === 1) {
      getDisputeData(2);
    } else if (index === 2) {
      getDisputeData(1);
    } else {
      getDisputeData(1);
    }
  };

  useEffect(() => {
    getDisputeData(1);
  }, []);

  return (
    <div>
      <Navbar text={"Dispute"} />
      <div className="m-4">
        <Tabs active={active} options={options} onChange={onChange} />
        {disputeData.length === 0 ? (
          <Empty />
        ) : (
          <div className="flex flex-col gap-6 w-[30%] my-10 mx-auto ">
            {disputeData.map((item) => {
              return <DisputeContainer key={item.id} {...item} />;
            })}
          </div>
        )}
      </div>
      <div className="w-full text-center">{loading && <LoadingOutlined />}</div>
    </div>
  );
}

export default Dispute;
