import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Navbar from "../../../Components/Navbar/Navbar";
import axios from "axios";
import { message } from "antd";
import moment from "moment";

function Schedular() {
  const [data, setData] = useState([]);

  const getData = async (date) => {
    try {
      const res = await axios.post("jobs/scheduled", {
        month: parseInt(moment(date?.start).format("MM"))+1,
        year: moment(date?.start).format("YYYY"),
      });

      const data = res.data.response.map((item) => {
        return {
          title: item.title?.toString(),
          description: item.location,
          location: item.location,
          date: moment(
            `${item.year}-${item.month}-${item.date}`,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DD"),
        };
      });
      setData(data);
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Navbar text={"Schedular"} />
      <div className="p-7 h-[80%]">
        <FullCalendar
          plugins={[dayGridPlugin]}
          height={"100%"}
          initialView="dayGridMonth"
          events={data}
          datesSet={getData}
        />
      </div>
      <div className="flex flex-col gap-1">
        {data.map((item) => {
          return (
            <div className="w-full rounded-3xl flex p-7">
              <div
                className="bg-[#f3faff] text-secondary p-6"
                style={{
                  borderRight: "5px solid #00A3FF",
                }}
              >
                <div>
                  {moment(item.date, "YYYY-MM-DD").format("DD MMM YYYY")}
                </div>
              </div>
              <div className="flex-1 bg-[#fff] flex justify-center pl-9 flex-col gap-1">
                <div className="text-base font-bold">Title: {item.title}</div>
                <div className="text-sm">Location: {item.location}</div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Schedular;
