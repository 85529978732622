import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import "./App.css";
import AppView from "./AppView";
import { loadStripe } from "@stripe/stripe-js";
import getFirebase from "./Service/Firebase";
import { setDefaults } from "react-geocode";

function App() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  setDefaults({
    key: process.env.REACT_APP_GOOGLE_MAP_KEY,
    language: "en",
    region: "us",
  });

  return (
    <>
      <Elements stripe={stripePromise}>
        <AppView />
      </Elements>
    </>
  );
}

export default App;
