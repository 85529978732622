import { Button, Image, Input, Upload, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../../Components/Navbar/Navbar";
import Tabs from "../../../Layouts/AppLayout/Tabs/Tabs";
import { InboxOutlined, UploadOutlined, UserOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";

function DisputeDetails() {
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const [disputeData, setDisputeData] = useState({});
  const [disputeMessage, setDisputeMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [textArea, setTextArea] = useState("");
  const [fileList, setFileList] = useState([]);

  const AdHocOption = ["Message", "File"];

  const onChangeTab = (index) => {
    if (index === 1) {
      getFileData();
    } else {
      getMessageData();
    }
    setActiveTab(index);
  };

  const getData = async () => {
    try {
      const res = await axios.post("view_dispute", {
        token: token,
      });
      setDisputeData(res?.data?.data);
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  const getMessageData = async () => {
    try {
      const res = await axios.post("dispute_answers", {
        token: token,
      });
      const temp = res?.data?.data?.filter(
        (item) => !item.answer.includes("http")
      );
      console.log(temp);
      setDisputeMessage(temp);
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  const getFileData = async () => {
    try {
      const res = await axios.post("dispute_files", {
        token: token,
      });
      setFile(res?.data?.data);
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  const sendMessageData = async () => {
    setLoading(true);
    try {
      const res = await axios.post("answer_dispute", {
        token: token,
        answer: textArea,
      });
      if (res?.data?.status) {
        message.success("Message Sent Successfully");
        getMessageData();
        setTextArea("");
        setLoading(false);
      }
    } catch (err) {
      message.error("Something went wrong");
      setLoading(false);
    }
  };

  const sendFileData = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("token", token);
    formData.append("file", fileList[0]?.originFileObj);
    try {
      const res = await axios.post("answer_dispute", formData);
      if (res?.data?.status) {
        message.success("Message Sent Successfully");
        setFileList([]);
        getFileData();
        setLoading(false);
      }
    } catch (err) {
      message.error("Something went wrong");
      setLoading(false);
    }
  };

  const handleUpload = (info) => {
    setFileList(info.fileList);
  };

  useEffect(() => {
    if (token) {
      getData();
      getMessageData();
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className="h-full">
      <Navbar text={disputeData?.officer_name} />
      <div className="flex px-[24px] py-[16px] h-[84%] gap-10">
        <div className="bg-[#fff] w-[25%] overflow-auto h-full rounded-md flex flex-col gap-3 py-[30px] px-[20px]">
          <div className={Container}>
            <div className={LabelClassname}>Created By</div>
            <div className={ValueClassname}>{disputeData?.created_by}</div>
          </div>
          <div className={Container}>
            <div className={LabelClassname}>Created On</div>
            <div className={ValueClassname}>{disputeData?.created_on}</div>
          </div>
          <div className={Container}>
            <div className={LabelClassname}>Time</div>
            <div className={ValueClassname}>{disputeData?.time}</div>
          </div>
          <div className={Container}>
            <div className={LabelClassname}>Officer Name</div>
            <div className={ValueClassname}>{disputeData?.officer_name}</div>
          </div>
          <div className={Container}>
            <div className={LabelClassname}>Applied On</div>
            <div className={ValueClassname}>{disputeData?.applied_on}</div>
          </div>
          <div className={Container}>
            <div className={LabelClassname}>Total Cost</div>
            <div className={ValueClassname}>{disputeData?.total_cost}</div>
          </div>
          <div className={Container}>
            <div className={LabelClassname}>Check in Date</div>
            <div className={ValueClassname}>{disputeData?.checkin_date}</div>
          </div>
          <div className={Container}>
            <div className={LabelClassname}>Check in Time</div>
            <div className={ValueClassname}>{disputeData?.checkin_time}</div>
          </div>
          <div className={Container}>
            <div className={LabelClassname}>Check out Time</div>
            <div className={ValueClassname}>{disputeData?.checkout_time}</div>
          </div>
          <div className={Container}>
            <div className={LabelClassname}>Agency Name</div>
            <div className={ValueClassname}>{disputeData?.agency_name}</div>
          </div>
          <div className={Container}>
            <div className={LabelClassname}>Job Created On</div>
            <div className={ValueClassname}>{disputeData?.job_created_on}</div>
          </div>
          <div className={Container}>
            <div className={LabelClassname}>Job Created By</div>
            <div className={ValueClassname}>{disputeData?.job_created_by}</div>
          </div>
          <div className={Container}>
            <div className={LabelClassname}>Description</div>
            <div className={ValueClassname}>{disputeData?.description}</div>
          </div>
        </div>
        <div className="w-[70%] overflow-auto">
          <Tabs
            active={activeTab}
            options={AdHocOption}
            onChange={onChangeTab}
          />
          <div className="flex gap-4 flex-col">
            {activeTab === 0 && (
              <>
                {disputeMessage.map((item, index) => {
                  return (
                    <div className="rounded-xl w-full p-4 bg-[#fff]">
                      <div className="flex gap-3 justify-center items-center">
                        <div className="h-12 w-12 bg-[#efefef] rounded-full flex justify-center items-center">
                          {item.avatar ? (
                            // <img src={item.avatar} />
                            <UserOutlined />
                          ) : (
                            <UserOutlined />
                          )}
                        </div>
                        <div className="flex-1">
                          <div className="text-secondary text-xl">
                            {item.name}
                          </div>
                          <div className="font-bold">{item.answer}</div>
                        </div>
                        <div>{item.created_at}</div>
                      </div>
                    </div>
                  );
                })}
                <div>
                  <Input.TextArea
                    placeholder="Add your answer to this dispute..."
                    style={{
                      height: "100px",
                      resize: "none",
                    }}
                    value={textArea}
                    onChange={(e) => {
                      setTextArea(e.target.value);
                    }}
                  />
                </div>
                <div className="w-full">
                  <Button
                    disabled={!textArea}
                    loading={loading}
                    type="primary w-full bg-secondary h-12"
                    onClick={sendMessageData}
                  >
                    Submit
                  </Button>
                </div>
              </>
            )}
            {activeTab == 1 && (
              <>
                {file.map((item, index) => {
                  return (
                    <div className="rounded-xl w-full p-10 bg-[#fff]">
                      <div className="flex gap-3 justify-center items-center">
                        <div className="h-12 w-12 bg-[#efefef] rounded-full flex justify-center items-center">
                          {item.avatar ? (
                            // <img src={item.avatar} />
                            <UserOutlined />
                          ) : (
                            <UserOutlined />
                          )}
                        </div>
                        <div className="flex-1">
                          <div className="text-secondary text-xl">
                            {item.name}
                          </div>
                          <div className="">
                            <Image src={item.file} />
                          </div>
                        </div>
                        <div>{item.created_at}</div>
                      </div>
                    </div>
                  );
                })}
                <div className="w-full text-center flex flex-col gap-4">
                  <Dragger
                    beforeUpload={() => {
                      return false;
                    }}
                    maxCount={1}
                    fileList={fileList}
                    onChange={handleUpload}
                    accept="image/*,.pdf"
                    className="w-full"
                    onPreview={(file) => {
                      const url = URL.createObjectURL(file.originFileObj);
                      window.open(url, "_blank");
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a singleupload. Strictly prohibited
                      from uploading company data or other banned files.
                    </p>
                  </Dragger>
                  <Button
                    disabled={fileList.length === 0}
                    loading={loading}
                    type="primary w-full bg-secondary h-12"
                    onClick={sendFileData}
                  >
                    Submit
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisputeDetails;

export const Container = "flex justify-between items-start";

export const LabelClassname = "text-[#707070] w-[50%] text-base font-normal";

export const ValueClassname = "text-[#000] w-[50%] text-base font-bold";
