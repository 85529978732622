import React from "react";
import AppRoute from "./Views/AppViews/Index";
import AuthRoute from "./Views/AuthViews/Index";
import { ApiService } from "./Service/Api";
import VerifyToken from "./Service/VerifyToken";

function AppView() {
  ApiService();
  VerifyToken();

  return <>{localStorage.getItem("token") ? <AppRoute /> : <AuthRoute />}</>;
}

export default AppView;
