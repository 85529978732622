import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar/Navbar";

import { Button, Form, Input, Select, message,Modal } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PRIVACYPOLICY, PROFILE, TERMSANDCONDITION } from "../../../Constants/Router/RouterConstant";
import { fetchDataForVerification } from "../../../Redux/Slice/VerifySlice";


function UpgradeMembership() {

  const [membershipData, setMembershipData] = useState({});
  const [cardData, setCardData] = useState([]);
  const [modelView, setModelView] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getMembershipData() {
    try {
      const res = await axios.get("/page/membership-benefits")
      // console.log(res);
      setMembershipData(res.data.response)
    } catch (error) {
      console.log("error", error);
    }
  }

 async function showModel() {
   setModelView(true);
   try {
    const res = await axios.post("/payment/cards")
     setCardData(res.data.response);
  } catch (error) {
    console.log("error", error);
  }
  }
  
  const handleCardChange = (value) => {
    setSelectedCard(value);
  }; 

  const handleStartSubscription = () => {
    if (selectedCard) {
      // Call your API with the selected card's value
      axios.post("/sa_subscription", { "payment_method": selectedCard })
        .then(response => {
          console.log(response);
          // dispatch(fetchDataForVerification());
          window.location.href="/"
        })
        .catch(error => {
          console.log(error);
        });
    }
  };


  useEffect(() => {
    getMembershipData();
  }, []);
  return (
    <>
      <Navbar text="Upgrade Membership Plan" />
      <div className="flex justify-center items-center py-14 w-[85vmin] m-auto flex-col gap-5 font-bold text-xl">
        <div className="flex items-center justify-center p- text-4xl font-bold text-[#49b1f2]  ">
          {membershipData.title}
        </div>
        <div className=" flex flex-col justify-center items-center w-full ">
          <h3 className="w-full pb-10 ">
            Benefits:
          </h3>
          <div className="w-full px-10 pb-5 flex flex-col gap-6"  dangerouslySetInnerHTML={{ __html: membershipData.content }}>
          </div>

        </div>
        <Button type="primary" className="bg-secondary px-20 py-6 flex justify-center items-center"
          onClick={() => 
            showModel()
          }
        >subscribe</Button>
        
        <div>
          <Button type="link" className="text-xl"
            onClick={() => { navigate(PROFILE + TERMSANDCONDITION)} 
          }
          >Terms & Condition</Button>
          <Button type="link" className="text-xl"
          onClick={() => { navigate(PROFILE + PRIVACYPOLICY)} 
        }>Privacy Policy</Button>
        </div>
      </div>
      <Modal
        closeIcon={true}
        title={false}
        open={modelView}
        className="modal"
        footer={null}
        onCancel={() => {
         setModelView(false)
        }}
        centered
      >
        <div className="p-3 flex flex-col gap-8">
          <h3>
            Select card for payment
          </h3>
          <Select
            
            placeholder="select card"
            onChange={handleCardChange}
            
            options={cardData.map(item => ({ value: item.id, label: item.card_no }))}
          />
          <Button type="primary" className="bg-secondary px-20 py-6 flex justify-center items-center" onClick={handleStartSubscription}> Start Subscription</Button>
        </div>
      </Modal>
    </>
  );
}

export default UpgradeMembership;
