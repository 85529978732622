import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseConfig = {
  apiKey: 'AIzaSyB47Lon7yDuMZJNSYgzvohl4h1LEjtkcOM',
  authDomain: 'sg-hired.firebaseapp.com',
  databaseURL: 'https://sg-hired-default-rtdb.firebaseio.com',
  projectId: 'sg-hired',
  storageBucket: 'sg-hired.appspot.com',
  messagingSenderId: '60308385810',
  appId: '1:60308385810:web:82dd116b2f3ff4e60868cb',
  measurementId: 'G-5BLRWEZJHW',
};

firebase.initializeApp(firebaseConfig);

export const Firebasedb = firebase.database();
