import React from "react";

function Tabs({ active, options, onChange }) {
  const length = options.length || 3;
  return (
    <>
      {options && (
        <div className="text-lg font-bold text-[#000] boxShadowTab flex justify-between rounded-2xl border-4 border-[#f3f3f3bb] py-1">
          {options.map((item, index) => {
            return (
              <div
                key={index}
                className={`text-center p-3 cursor-pointer${
                  active === index ? "text-secondary boxOptionShadowActive" : ""
                } `}
                onClick={() => {
                  onChange(index);
                }}
                style={{
                  width: `calc(100% / ${length})`,
                }}
              >
                {item}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default Tabs;
