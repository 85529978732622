import { Layout } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  ADHoc,
  ChangeViewIcon,
  DashboardIcon,
  Filter,
  Logo,
  Notification,
  Permanent,
  SearchForJob,
  Transaction,
  UndoChangeViewIcon,
  User,
} from "../../../Assets/ExportedIcon/Icon";
import {
  ADHOC,
  DASHBOARD,
  PERMANENT,
  PROFILE,
  TRANSACTION,
} from "../../../Constants/Router/RouterConstant";
import { useDispatch, useSelector } from "react-redux";
import { setViewJobStyle } from "../../../Redux/Slice/ViewJobStyle";
import { ShowSidebar } from "../../../Constants/ShowSidebar.js/ShowSidebar";
import { setNotification } from "../../../Redux/Slice/NotificationSlice";
import Notifications from "../Notification/Notification";
import { setFilter } from "../../../Redux/Slice/FilterSlice";
import { setAdhocSearch } from "../../../Redux/Slice/SearchSlice";

function SideBar() {
  const { Sider } = Layout;
  const location = useLocation();
  const dispatch = useDispatch();
  const { value } = useSelector((state) => state.viewJobStyle);
  const { notification } = useSelector((state) => state);
  const {jobTab} = useSelector((state) => state);

  const sidebarTab = [
    {
      icon: <DashboardIcon />,
      title: "Dashboard",
      link: DASHBOARD,
      active: location.pathname === DASHBOARD,
    },
    {
      icon: <ADHoc />,
      title: "AD Hoc",
      link: ADHOC,
      active: location.pathname === ADHOC,
    },
    {
      icon: <Permanent />,
      title: "Permanent",
      link: PERMANENT,
      active: location.pathname === PERMANENT,
    },
    {
      icon: <Transaction />,
      title: "Transaction",
      link: TRANSACTION,
      active: location.pathname === TRANSACTION,
    },
    {
      icon: <User />,
      title: "Profile",
      link: PROFILE,
      active: location.pathname === PROFILE,
    },
  ];

  if (ShowSidebar.includes(location.pathname)) {
    return (
      <Sider
        className="sidebar flex-col flex justify-between"
        collapsed={false}
      >
        <div className="text-center px-3 pt-6 pb-4">
          <Logo />
        </div>
        <div className="flex-1">
          {sidebarTab.map((item, index) => {
            return (
              <div
                key={index}
                className={`flex justify-center p-4 sidebar-tab-container hover:bg-[#0B66A5] ${
                  item.active && "siderbar-tab-container-active"
                }`}
              >
                <div className="flex justify-center items-center">
                  <Link to={item.link}>
                    <div className="flex justify-center items-center mb-2">
                      {item.icon}
                    </div>
                    <div className="sidebar-tab">{item.title}</div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex gap-3 flex-col">
          {(location.pathname === ADHOC || location.pathname === PERMANENT) && (
            <div>
              <div className="flex justify-center flex-col items-center">
                {value ? (
                  <div
                    className="rounded-full bg-white p-2 cursor-pointer"
                    onClick={() => {
                      dispatch(setViewJobStyle(0));
                    }}
                  >
                    <ChangeViewIcon />
                  </div>
                ) : (
                  <div
                    className="rounded-full bg-white p-2 cursor-pointer"
                    onClick={() => {
                      dispatch(setViewJobStyle(1));
                    }}
                  >
                    <UndoChangeViewIcon />
                  </div>
                )}
              </div>
            </div>
          )}
          {(location.pathname === ADHOC || location.pathname === PERMANENT || location.pathname === DASHBOARD) &&
            <div>
              <div className="flex justify-center flex-col items-center">
                <div
                  className="rounded-full bg-white p-2 cursor-pointer"
                  onClick={() => {
                    if (location.pathname === DASHBOARD) {
                      dispatch(setFilter({ dashboard: true }));
                    } else if (location.pathname === ADHOC) {
                      dispatch(setFilter({ adhoc: true }));
                    } else if (location.pathname === PERMANENT) {
                      dispatch(setFilter({ permanent: true }));
                    }
                  }}
                >
                  <Filter />
                </div>
              </div>
            </div>
          }
          {((location.pathname === ADHOC || location.pathname === PERMANENT) && jobTab!==1 )&& (
            <div>
              <div className="flex justify-center flex-col items-center">
                <div
                  className="rounded-full bg-white p-2 cursor-pointer"
                  onClick={() => {
                    if (location.pathname === ADHOC) {
                      dispatch(setAdhocSearch({ adhoc: true }));
                    } else if (location.pathname === PERMANENT) {
                      dispatch(setAdhocSearch({ permanent: true }));
                  }}
                }
                >
                  <SearchForJob />
                </div>
              </div>
            </div>
          )}
          <div
            className={`flex justify-center flex-col p-4 cursor-pointer sidebar-tab-container hover:bg-[#0B66A5] `}
          >
            <div
              onClick={() => {
                dispatch(setNotification(!notification));
              }}
              className="flex justify-center flex-col items-center"
            >
              <div className="flex justify-center items-center mb-2">
                {<Notification />}
              </div>
              <div className="sidebar-tab">Notification</div>
            </div>
          </div>
        </div>
        <Notifications />
      </Sider>
    );
  } else {
    return <></>;
  }
}

export default SideBar;
