import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboard: false,
  adhoc: false,
  permanent: false,
};

const filterSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setFilter } = filterSlice.actions;

export default filterSlice.reducer;
