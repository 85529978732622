import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import { Button, Card, Empty, message } from "antd";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";

function BlackList() {
  const [backListData, setBackListData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      const res = await axios.get("/blacklisted-officers");
      setBackListData(res.data?.data);
    } catch (err) {
      message.error("Error while fetching data");
    }
  };

  const unblock = async (id) => {
    try {
      setLoading(true);
      const res = await axios.post(`/remove-from-blacklist`,{
        officer_id:id
      });
      if (res.status === 200) {
        message.success("Officer unblocked");
        getData();
      }
    } catch (err) {
      message.error("Error while unblocking officer");
    } finally {
      setLoading(false);
    }
  
  }

  useEffect(() => {
    getData();
  }, []);


  return (
    <>
      <Navbar text={"Black List"} />
      <div className="w-[40%] m-auto p-10">
        <div className="flex flex-col w-full justify-center items-center">
          {loading ? <LoadingOutlined /> : null}
          {backListData.length !== 0 ? (
            <>
              {backListData.map((item, index) => {
                return (
                  <Card className="w-full ">
                    <div className="flex justify-between items-center">
                      <div className="font-bold text-3xl">{item?.name}</div>
                      <Button onClick={()=>{
                        unblock(item.id)
                      }} className="text-[red] cursor-pointer">
                        Unblock
                      </Button>
                    </div>
                  </Card>
                );
              })}
            </>
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </>
  );
}

export default BlackList;
