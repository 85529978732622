import { message } from "antd";
import axios from "axios";

const VerifyToken = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const response = await axios("check", {
        method: "POST",
      });
      const data = await response.json();
    } catch (err) {
      if (err?.response?.data?.error === "Unauthenticated.") {
        message.error("Login expired, please login again");
        localStorage.removeItem("token");
        window.location.href = "/";
      }
    }
  } else {
  }
};

export default VerifyToken;
