import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import {
  Button,
  Card,
  Carousel,
  DatePicker,
  Divider,
  Empty,
  Input,
  Modal,
  Select,
  Switch,
  message,
} from "antd";
import axios from "axios";
import {
  ArrowLeftOutlined,
  LikeOutlined,
  LikeTwoTone,
  LoadingOutlined,
  MessageOutlined,
  SendOutlined,
} from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import Search from "antd/es/input/Search";
import moment from "moment";

function Announcement() {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [blogDetails, setBlogDetails] = useState({});
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [featured, setFeatured] = useState(false);
  const [blogData, setBlogData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDate, setSelectedDate] = useState([null, null]);

  const [time, setTime] = useState("");
  const getBlogDetails = async (id) => {
    try {
      const res = await axios.post("blog/detail", { id: id });
      setBlogDetails(res.data.data);
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  const getBlogCategoies = async () => {
    try {
      const res = await axios.get("blog/categories");
      setBlogData(res.data.data);
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  const getLike = async (id) => {
    try {
      const res = await axios.post("blog/like", {
        id: id,
      });

      getBlogDetails(id);
    } catch (err) {
      message.error(err.response.data.message);
    }
  };

  const getComment = async (id, comment) => {
    setLoading(true);
    try {
      const res = await axios.post("blog/comment", {
        id: 3,
        comment_body: comment,
      });

      setComment("");
      getBlogDetails(id);
    } catch (err) {
      message.error(err.response.data.message);
    }
    setLoading(false);
  };

  const getData = async (search = "") => {
    let data = {
      page: page,
      search: search,
      fromDate: selectedDate[0],
      toDate: selectedDate[1],
      featured: featured ? 1 : 0,
      category: selectedCategory,
    };

    try {
      const res = await axios.post("blog/list", {
        ...data,
      });
      if (res.data.status === false) {
        setData([]);
      } else {
        setData(res.data.data);
        console.log(res.data.meta.total);
        setTotal(res.data.meta.total);
      }
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  useEffect(() => {
    getBlogCategoies();
  }, []);

  useEffect(() => {
    getData();
  }, [page, featured, selectedCategory, selectedDate]);

  return (
    <div>
      <Navbar text={"Announcement"} />
      <div className="text-right pt-10 pr-10 flex justify-end items-center gap-3">
        <div>
          <DatePicker.RangePicker
            onChange={(e, str) => {
              console.log(str);
              if (str[0] === "" || str[1] === "") {
                setSelectedDate([null, null]);
              } else {
                setSelectedDate([
                  moment(str[0], "YYYY-MM-DD").format("DD/MM/YYYY"),
                  moment(str[1], "YYYY-MM-DD").format("DD/MM/YYYY"),
                ]);
              }
            }}
          />
        </div>
        <div>
          <Select
            style={{
              width: 200,
            }}
            placeholder="Select Category"
            onChange={(e) => {
              setSelectedCategory(e);
            }}
            allowClear
          >
            {blogData.map((item) => {
              return <Select.Option value={item.id}>{item.name}</Select.Option>;
            })}
          </Select>
        </div>
        <div className="switch-class">
          <Switch
            checkedChildren="Featured"
            unCheckedChildren="All"
            type="primary"
            onChange={(e) => {
              setFeatured(e);
            }}
          />
        </div>
        <Search
          className="w-48"
          placeholder="Search"
          onChange={(e) => {
            clearTimeout(time);
            setTime(
              setTimeout(() => {
                getData(e.target.value);
              }, 1000)
            );
          }}
        />
      </div>
      <InfiniteScroll
        dataLength={total}
        next={() => {
          setPage(page + 1);
        }}
        hasMore={true}
        loader={<LoadingOutlined />}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        {data.length === 0 ? (
          <Card className="w-[95%] my-20 mx-auto">
            <Empty />
          </Card>
        ) : (
          <Card className="w-[95%] my-20 mx-auto">
            {data?.map((item) => {
              return (
                <Card.Grid
                  onClick={() => {
                    getBlogDetails(item.id);
                    setVisible(true);
                  }}
                  className="cursor-pointer"
                >
                  {
                    <div>
                      <img
                        className="h-48 w-full object-cover"
                        src={item.featured_image}
                      />
                      <div className="font-bold text-lg">{item.title}</div>
                    </div>
                  }
                </Card.Grid>
              );
            })}
          </Card>
        )}
      </InfiniteScroll>

      <Modal
        title={false}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        closeIcon={false}
        className="modal"
        footer={false}
        centered
        height={600}
      >
        <div>
          <div className="h-24 w-full bg-secondary justify-between items-center px-2 text-white flex">
            <div className="flex-1 flex justify-start items-center h-full gap-2 px-3">
              <div>
                <ArrowLeftOutlined
                  onClick={() => setVisible(false)}
                  className="text-xl"
                />
              </div>
              <div className="relative flex-1">
                <div className="text-xl font-bold">Announcement Details</div>
              </div>
            </div>
          </div>
          <div className="overflow-auto h-full">
            <Carousel>
              {blogDetails?.images?.map((item) => {
                return (
                  <div>
                    <img className="w-full h-[300px] object-cover" src={item} />
                  </div>
                );
              })}
            </Carousel>
            <div className="flex justify-between p-3">
              <div
                className="flex gap-3 cursor-pointer"
                onClick={() => {
                  getLike(blogDetails?.id);
                }}
              >
                {blogDetails?.isLiked ? <LikeTwoTone /> : <LikeOutlined />}
                {blogDetails?.like_count} Likes
              </div>
              <div className="flex gap-3">
                <MessageOutlined />
                {blogDetails?.comment_count} Comments
              </div>
            </div>
            <div className="font-bold text-lg p-3">{blogDetails?.title}</div>
            <Divider />
            <div className="p-3">
              <div>{blogDetails?.date}</div>

              <div
                className="font-bold text-lg"
                dangerouslySetInnerHTML={{ __html: blogDetails?.description }}
              ></div>
              <div className="font-extrabold text-xl mt-2">Comments</div>
              <div className="">
                {blogDetails?.comments?.map((item) => {
                  return (
                    <div className="flex">
                      <div>
                        <img
                          src={item.avatar}
                          className="h-10 w-10 object-cover"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div>{item.comment_body}</div>
                        <div className="font-bold">
                          {item.author}, {item.date}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div>
                <Input
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                  suffix={
                    <Button
                      loading={loading}
                      className="cursor-pointer"
                      onClick={() => {
                        getComment(blogDetails?.id, comment);
                      }}
                    >
                      <SendOutlined />
                    </Button>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Announcement;
