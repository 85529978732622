import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import LabelForInput from "../../../Components/Inputs/LabelForInput/LabelForInput";

import CommonButton from "../../../Components/Button/Button";
import { fromLatLng, setDefaults } from "react-geocode";

import { Button, Form, Input, Select, message } from "antd";
import { PERSONAL_DETAILS } from "../../../Constants/Router/RouterConstant";
import { fetch_address } from "../../../utils/SearchAddress";
import { EnvironmentOutlined, InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import { countryCode } from "../../../Constants/CountryCode/CountryCode";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTempCompanyData } from "../../../Redux/Slice/TempCompanyData";
import LocationSearch from "../../../Components/LocationSearch/LocationSearch";

function CompanyDetails() {
  const [form] = Form.useForm();
  const [value, setValue] = useState();
  const [data, setData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryCodes, setCountryCode] = useState("+65");
  const [countryCodeIso, setCountryCodeIso] = useState("SG");
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [LocationData, setLocationData] = useState(null);

  const handleUpload = (info) => {
    setFileList(info.fileList);
  };

  const currentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      fromLatLng(position.coords.latitude, position.coords.longitude).then(
        (json) => {
          setLocation(json.results[0].formatted_address);
        }
      );
    });
  };

  const fetchFunction = async (newValue) => {
    const res = await fetch_address(newValue);
    console.log(res);
  };

  let timer;
  const handleSearch = (newValue) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fetchFunction(newValue);
    }, 2000);
  };
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const onFinish = async(values) => {
    const formData = new FormData();
    formData.append("company_name", values.companyName);
    formData.append("company_email", values.email);
    formData.append("company_mobile_number", phone);
    formData.append("company_country_code", countryCodes);
    formData.append("company_country_code_iso", countryCodeIso);
    formData.append("uen_number", values.uen);
    formData.append("unit_number", values.unit);
    formData.append("location",LocationData.address);
    formData.append("latest_grade", values.latestgrade);
    formData.append("enrollment_cert", fileList[0]?.originFileObj);
   try{
    const res = await axios.post("sa_company_registration", formData);
    // console.log(res);
    dispatch(setTempCompanyData({
      company_name: values.companyName,
      company_email: values.email,
      company_mobile_number: phone,
      company_country_code: countryCodes,
      company_country_code_iso: countryCodeIso,
      uen_number: values.uen,
      unit_number: values.unit,
      location: values.location,
      latest_grade: values.latestgrade,
      enrollment_cert: fileList[0]?.originFileObj,
    }));
    message.success(res?.data?.message);
    navigate(PERSONAL_DETAILS);
   }
   catch(err){
    message.error(err?.response?.data?.message)
     console.log(err);
   }
  };

  return (
    <>
      <Navbar text="Company Details" />
      <div className="flex justify-center items-center pt-10 w-80 m-auto flex-col gap-5 pb-14">
        <Form form={form} onFinish={onFinish}>
          <div className="w-full">
            <LabelForInput text="Enter Company Name *" />
            <Form.Item
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Please Enter Company Name",
                },
              ]}
            >
              <Input className="h-12" placeholder="Enter Company Name" />
            </Form.Item>
          </div>
          <div className="w-full">
            <LabelForInput text="Email Address *" />
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please Enter Email Address",
                  pattern: new RegExp(
                    /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/
                  ),
                },
              ]}
            >
              <Input className="h-12" placeholder="Enter Email" />
            </Form.Item>
          </div>
          <div className="w-full">
            <LabelForInput text="Mobile Number *" />
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please Enter Mobile Number",
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
            >
              <div className="flex w-full">
                <div className="w-[30%]">
                  <Select
                    value={countryCodeIso}
                    onChange={(e, str) => {
                      setCountryCodeIso(str.children);
                      setCountryCode(str.value);
                    }}
                    className="h-12"
                  >
                    {countryCode.map((item) => {
                      return (
                        <Select.Option value={item.code}>
                          {item.dial_code}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
                <Input
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  className="h-12 w-[95%]"
                  placeholder="Enter Mobile Number"
                />
              </div>
            </Form.Item>
          </div>
          <div className="w-full">
            <LabelForInput text="Enter Uen Number *" />
            <Form.Item
              name="uen"
              rules={[
                {
                  required: true,
                  message: "Please Enter UEN Number",
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
            >
              <Input className="h-12" placeholder={"Enter UEN Number"} />
            </Form.Item>
          </div>
          <div className="w-full">
            <LabelForInput text="Latest Grade *" />
            <Form.Item
              name="latestgrade"
              rules={[
                {
                  required: true,
                  message: "Please Select Latest Grade",
                },
              ]}
            >
              <Select
                placeholder={"Select"}
                className="h-12"
                options={[
                  {
                    label: "Competent",
                    value: "Competent",
                  },
                  {
                    label: "Non Competent",
                    value: "Non Competent",
                  },
                  {
                    label: "Exempted",
                    value: "Exempted",
                  },
                  {
                    label: "A",
                    value: "A",
                  },
                  {
                    label: "B",
                    value: "B",
                  },
                  {
                    label: "C",
                    value: "C",
                  },
                  {
                    label: "D",
                    value: "D",
                  },
                ]}
              />
            </Form.Item>
          </div>
          <div className="w-full">
            <LabelForInput text="Unit No" />
            <Form.Item
              name="unit"
              rules={[
                {
                  required: true,
                  message: "Please Enter Unit Number",
                },
              ]}
            >
              <Input placeholder="Unit Number" className="h-12" />
            </Form.Item>
          </div>
          <div className="w-full mb-4">
          <div>Set Location Distance</div>
              <LocationSearch setLocationData={setLocationData}/>
              <div className=" flex justify-between">
                <div
                  onClick={currentLocation}
                  className="text-secondary cursor-pointer"
                >
                  <EnvironmentOutlined /> Current Location
                </div>
            </div>
            </div>
          <div className="w-full text-center flex flex-col gap-4 mb-4">
            <Dragger
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
              fileList={fileList}
              onChange={handleUpload}
              accept="image/*,.pdf"
              className="w-full"
              onPreview={(file) => {
                const url = URL.createObjectURL(file.originFileObj);
                window.open(url, "_blank");
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a singleupload. Strictly prohibited from uploading
                company data or other banned files.
              </p>
            </Dragger>
          </div>
          <div className="w-full">
            <Form.Item>
              <CommonButton
                text="Submit"
                type="submit"
                color={"bg-secondary"}
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
}

export default CompanyDetails;
