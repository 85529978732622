import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar/Navbar";
// import InputText from "../../../Components/Inputs/InputText/InputText";
import CommonButton from "../../../Components/Button/Button";
import { Form, Input, Select, Upload, message } from "antd";
import LabelForInput from "../../../Components/Inputs/LabelForInput/LabelForInput";
// import EmailInput from "../../../Components/Inputs/EmailInput/EmailInput";
// import InputNumberForMobile from "../../../Components/Inputs/InputPhoneNumber/InputNumberForMobile";
// import InputPassword from "../../../Components/Inputs/Password/InputPassword";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CARD_DETAILS,
  COMPANY_DETAILS,
  PERSONAL_DETAILS,
} from "../../../Constants/Router/RouterConstant";
import { useSelector } from "react-redux";
import axios from "axios";
import { countryCode as country } from "../../../Constants/CountryCode/CountryCode";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { API_BASE_URL } from "../../../Constants/API/ApiConstant";

function PersonalDetails() {
  const [form] = Form.useForm();
  const [countryCode, setCountryCode] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);

  const { tempCompanyData } = useSelector((state) => state);
  const [phone, setPhone] = useState("");
  const [countryCodes, setCountryCodes] = useState("+65");
  const [countryCodeIso, setCountryCodeIso] = useState("SG");
  const onFinish = async (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("mobile_number", phone);
    formData.append("country_code_iso", countryCodeIso);
    formData.append("country_code", countryCodes);
    formData.append("position", values.position);


    if (location.pathname === PERSONAL_DETAILS) {
      formData.append("nationality", values.country_code);
      formData.append(
        "company_name",
        tempCompanyData?.tempCompanyData?.company_name
      );
      formData.append(
        "company_email",
        tempCompanyData?.tempCompanyData?.company_email
      );
      formData.append(
        "company_mobile_number",
        tempCompanyData?.tempCompanyData?.company_mobile_number
      );
      formData.append(
        "company_country_code",
        tempCompanyData?.tempCompanyData?.company_country_code
      );
      formData.append(
        "company_country_code_iso",
        tempCompanyData?.tempCompanyData?.company_country_code_iso
      );
      formData.append(
        "uen_number",
        tempCompanyData?.tempCompanyData?.uen_number
      );
      formData.append(
        "unit_number",
        tempCompanyData?.tempCompanyData?.unit_number
      );
      formData.append("location", tempCompanyData?.tempCompanyData?.location);
      formData.append(
        "latest_grade",
        tempCompanyData?.tempCompanyData?.latest_grade
      );
      formData.append("password", tempCompanyData?.tempCompanyData?.password);
      formData.append(
        "password_confirmation",
        tempCompanyData?.tempCompanyData?.password_confirmation
      );
      formData.append(
        "enrollment_cert",
        tempCompanyData?.tempCompanyData?.enrollment_cert
      );
    }
    try {
      const res = await axios.post("/signup_agency", formData);
      message.success(res?.data?.message);
      localStorage.setItem("token", res?.data?.response?.token);

      navigate(CARD_DETAILS);
    } catch (err) {
      console.log(err);
      message.error(err?.response?.data?.message);
    }
  };

  const getCountryCode = async () => {
    try {
      const res = await axios.get("/nationality");
      setCountryCode(res?.data?.response);
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    try {
      const res = await axios.get("/sa_fetch_agency");
      const data = res?.data?.response;
      console.log(data);
      setImageUrl(data?.avatar);
      form.setFieldsValue({
        name: data?.name,
        email: data?.email,
        position: data?.position,
      });

      setPhone(data?.mobile_number);
    } catch (err) {
      message.error(err?.response?.data?.message);
    }
  };
  useEffect(() => {
    if (!tempCompanyData) {
      navigate(COMPANY_DETAILS);
    } else {
      getCountryCode();
      if (location.pathname === PERSONAL_DETAILS) {
      } else {
        getData();
      }
    }
  }, []);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageUrl(imageUrl);
        setLoading(false);
      });
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <>
      {location.pathname === PERSONAL_DETAILS ? (
        <Navbar text="Personal Details" />
      ) : (
        <Navbar text="Edit Profile" />
      )}
      <div className="flex justify-center items-center pt-10 w-80 m-auto flex-col gap-5 pb-14">
        <Form form={form} onFinish={onFinish}>
          {
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              method="post"
              action={API_BASE_URL + `/sa_update_profile_image`}
              headers={{
                authorization: `Bearer ${localStorage.getItem("token")}`,
                apiToken: process.env.REACT_APP_API_TOKEN,
              }}
              // action={handleAction}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              accept="image/*"
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          }
          <div className="w-full">
            <LabelForInput text="Name *" />
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please Enter Name",
                },
              ]}
            >
              <Input className="h-12" placeholder="Enter your Name" />
            </Form.Item>
          </div>
          <div className="w-full">
            <LabelForInput text="Email Address *" />
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please Enter Email Address",
                  pattern: new RegExp(
                    /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/
                  ),
                },
              ]}
            >
              <Input className="h-12" placeholder="Enter your email" />
            </Form.Item>
          </div>
          <div className="w-full">
            <LabelForInput text="Mobile Number *" />
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please Enter Mobile Number",
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
            >
              <div className="flex w-full">
                <div className="w-[30%]">
                  <Select
                    value={countryCodeIso}
                    onChange={(e, str) => {
                      setCountryCodeIso(str.children);
                      setCountryCodes(str.value);
                    }}
                    className="h-12"
                  >
                    {country.map((item) => {
                      return (
                        <Select.Option value={item.code}>
                          {item.dial_code}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
                <Input
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                  className="h-12 w-[95%]"
                  placeholder="Enter Mobile Number"
                />
              </div>
            </Form.Item>
          </div>
          <div className="w-full">
            <LabelForInput text="Position In Company *" />
            <Form.Item
              name="position"
              rules={[
                {
                  required: true,
                  message: "Please Enter Position In Company",
                },
              ]}
            >
              <Input className="h-12" placeholder="Enter Position In Company" />
            </Form.Item>
          </div>
          {location.pathname === PERSONAL_DETAILS && (
            <div>
              <LabelForInput text="Nationality*" />
              <Form.Item
                name="country_code"
                rules={[
                  {
                    required: true,
                    message: "Please Select Nationality",
                  },
                ]}
              >
                <Select
                  className="w-full h-12"
                  showSearch
                  placeholder="Select Nationality"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {countryCode.map((item) => {
                    return (
                      <Select.Option value={item.value}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          )}
          {/* <div className="w-full">
            <LabelForInput text="Password *" />
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please Enter Password",
                },
              ]}
            >
              <Input className="h-12" placeholder="Enter Password"/>
            </Form.Item>
          </div>
          <div className="w-full">
            <LabelForInput text="Confirm Password *" />
            <Form.Item
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: "Please Enter Password",
                },
              ]}
            >
              <Input className="h-12" placeholder="Enter Password" />
            </Form.Item>
          </div> */}
          {location.pathname === PERSONAL_DETAILS && (
            <div className="text-secondary font-normal my-6">
              By registering you agree to our{" "}
              <span className="underline cursor-pointer">
                Terms & Conditions
              </span>
            </div>
          )}
          <div className="w-full">
            <Form.Item>
              <CommonButton
                text={
                  location.pathname === PERSONAL_DETAILS ? "Submit" : "Save"
                }
                type="submit"
                color={"bg-secondary"}
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
}

export default PersonalDetails;
