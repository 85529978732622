import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: 0,
}

export const viewJobStyleSlice = createSlice({
    name: 'viewJobStyle',
    initialState,
    reducers: {
        setViewJobStyle: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { setViewJobStyle } = viewJobStyleSlice.actions

export default viewJobStyleSlice.reducer