import React from 'react'

function LabelForInput({text}) {
  return (
    <div className='font-normal text-sm text-left w-full'>
        {text}
    </div>
  )
}

export default LabelForInput