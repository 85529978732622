import { configureStore } from '@reduxjs/toolkit'
import { viewJobStyleSlice } from './Slice/ViewJobStyle'
import { dataReducer } from './Slice/PositionSlice'
import { Verify } from './Slice/VerifySlice'
import NotificationSlice from './Slice/NotificationSlice'
import FilterSlice from './Slice/FilterSlice'
import SearchSlice from './Slice/SearchSlice'
import JobTabSlice from './Slice/JobTabSlice'
import TempCompanyData from './Slice/TempCompanyData'


export const store = configureStore({
  reducer: {
    viewJobStyle: viewJobStyleSlice.reducer,
    positionSlice: dataReducer,
    verifySlice: Verify,
    notification: NotificationSlice,
    filter:FilterSlice,
    search:SearchSlice,
    jobTab:JobTabSlice,
    tempCompanyData:TempCompanyData
  },
})