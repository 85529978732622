import axios from "axios";
import { API_BASE_URL } from "../Constants/API/ApiConstant";

export const ApiService = () => {
  axios.defaults.baseURL = API_BASE_URL;
  axios.defaults.headers.common["apitoken"] = process.env.REACT_APP_API_TOKEN;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Content-Type"]= "multipart/form-data";
  axios.defaults.headers.common["authorization"] =
    "Bearer " + localStorage.getItem("token");
};
