import { ClockCircleOutlined, MessageOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DISPUTE, DISPUTE_DETAILS, PROFILE } from "../../../../Constants/Router/RouterConstant";

function DisputeContainer({ avatar, title,token, time,dispute_type ,description,created_by}) {
    const navigate = useNavigate();
    return (
    <>
      <div
      onClick={()=>{
        navigate(`${PROFILE+DISPUTE+DISPUTE_DETAILS}?token=${token}`);
      }}
        className="cursor-pointer w-full p-3 rounded-2xl flex flex-col gap-1"
        style={{
          border: "1px solid green",
        }}
      >
        <div className="flex items-center justify-center gap-3">
          <div className="h-12 w-12">
            <img src={avatar} />
          </div>
          <div className="flex-1 font-bold text-2xl">
            <div>{title}</div>
          </div>
          <div className="text-third flex gap-1 text-xs">
            <ClockCircleOutlined />
            <div>{time}</div>
          </div>
        </div>
        <div className="text-secondary font-semibold">
            Dispute Type
        </div>
        <div>
            {dispute_type}
        </div>
        <div style={{
            borderTop:"1px solid #efefef"
        }}>
            {description}
        </div>
        <div className="flex gap-2 justify-end">
            <MessageOutlined/>{created_by}
        </div>
      </div>
    </>
  );
}

export default DisputeContainer;
