import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Define the async thunk
export const fetchDataForVerification = createAsyncThunk(
  "data/fetchDataCheck",
  async (_, { rejectWithValue }) => {
    try {
      // Make API call to fetch data
      const response = await axios.post("check");
      // Return the data fetched from the API
      return response.data;
    } catch (error) {
      // Handle errors
      return rejectWithValue(error.response.data);
    }
  }
);

// Define initial state
const initialState = {
  data: [],
  loading: false,
  error: null,
};

// Create a slice
const VerifySlice = createSlice({
  name: "datas",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle pending state
      .addCase(fetchDataForVerification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // Handle fulfilled state
      .addCase(fetchDataForVerification.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      // Handle rejected state
      .addCase(fetchDataForVerification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const VerifyAction = VerifySlice.actions;
export const Verify = VerifySlice.reducer;
