import { Drawer, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from "../../../Redux/Slice/NotificationSlice";
import Navbar from "../../../Components/Navbar/Navbar";
import {
  ArrowLeftOutlined,
  BackwardFilled,
  BackwardOutlined,
  ClockCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { Info } from "../../../Assets/ExportedIcon/Icon";

function Notifications() {
  const { notification } = useSelector((state) => state);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(setNotification(false));
  };

  const getNotification = async () => {
    try {
        setLoading(true);
      const res = await axios.get("notifications");
      setData(res?.data?.data);
        setLoading(false);
    } catch (err) {
      console.log(err);
      message.error(err?.response?.data?.message);
    }
  };

  const clearAll = async () => {
    try{
        const res = await axios.post("update_notifications",{
            all:true
        });
        
        getNotification();
    }catch(err){
        console.log(err);
        message.error(err?.response?.data?.message);
    }
  }
  useEffect(() => {
    getNotification();
  }, []);
  return (
    <div className="notification-drawer">
      <Drawer
        open={notification}
        title={"Notifications"}
        width={900}
        styles={{
          header: {
            display: "none",
          },
        }}
        onClose={onClose}
      >
        <div>
          <div className="h-24 w-full bg-secondary justify-between items-center px-2 text-white flex">
            <div className="flex-1 flex justify-start items-center h-full gap-2 px-3">
              <div>
                <ArrowLeftOutlined
                  onClick={() => dispatch(setNotification(false))}
                  className="text-xl"
                />
              </div>
              <div className="relative flex-1">
                <div className="text-xl font-bold">Notification</div>
              </div>
              <div onClick={clearAll} className="cursor-pointer">
                Clear All
              </div>
            </div>
          </div>
          {
            loading?
            <div className="flex justify-center items-center h-full"><LoadingOutlined className="text-lg"/></div>:
          
          <div className="flex gap-5 flex-col">
          {data.map((item, index) => {
            return (
              <div key={index} style={{
                boxShadow: "0px 8px 16px #0000000A"
              }} className={`flex gap-7 py-[20px] px-[20px]  items-start ${item.is_read?"":"bg-[#E9EAEA]"}`}>
                <div>
                  <Info />
                </div>
                <div className="flex-1">
                  <div style={{fontSize:"16px"}} className="font-bold">{item.title}</div>
                  <div className="text-xs text-[#707070]">{item.message}</div>
                </div>
                <div className="text-xs flex"><ClockCircleOutlined/><div className="ml-2">{item.date}</div></div>
              </div>
            );
          })}
          </div>
}
        </div>
      </Drawer>
    </div>
  );
}

export default Notifications;
