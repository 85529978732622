import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adhoc: false,
  permanent: false,
};

const adhocSearchSlice = createSlice({
  name: "adhocSearch",
  initialState,
  reducers: {
    setAdhocSearch: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setAdhocSearch } = adhocSearchSlice.actions;
export default adhocSearchSlice.reducer;
