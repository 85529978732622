import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  CARD_DETAILS,
  COMPANY_DETAILS,
  LOGIN,
  MOBILE_VERIFICATION,
  OTP_VERIFICATION,
  PERSONAL_DETAILS,
} from "../../Constants/Router/RouterConstant";
import { LoadingOutlined } from "@ant-design/icons";
import CompanyDetails from "./CompanyDetails/CompanyDetails";
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import AddCard from "../AppViews/AddCard/AddCard";


const Login = lazy(() => import("./Login/Login"));
const Mobile = lazy(() => import("./Mobile/Mobile"));
const OtpVerification = lazy(() => import("./OtpVerification/OtpVerification"));

function Index() {
  return (
    <>
      <Router>
        <Suspense
          fallback={
            <div className="w-full h-full flex justify-center items-center">
              <LoadingOutlined />
            </div>
          }
        >
          <Routes>
            <Route path={LOGIN} element={<Login />} />
            <Route path={MOBILE_VERIFICATION} element={<Mobile />} />
            <Route path={OTP_VERIFICATION} element={<OtpVerification />} />
            <Route path={COMPANY_DETAILS} element={<CompanyDetails />} />
            <Route path={PERSONAL_DETAILS} element={<PersonalDetails />} />
            <Route path={CARD_DETAILS} element={<AddCard/>} />
            
            <Route path="*" element={<Navigate to={LOGIN} />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default Index;
