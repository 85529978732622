import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  ABOUT,
  ADD_JOB,
  ADD_PERMANENT_JOB,
  ADHOC,
  BLACKLIST,
  CHAT_SUPPORT,
  CREDIT_CARD,
  DASHBOARD,
  DISPUTE,
  DISPUTE_DETAILS,
  EDIT_JOB,
  EDIT_PROFILE,
  JOB_DETAIL,
  MANAGE_CARD,
  OFFICERS_CHAT,
  PERMANENT,
  PERMANENT_JOB_DETAIL,
  PROFILE,
  SCHEDULAR,
  SELECT_CARD,
  TERMSANDCONDITION,
  TRANSACTION,
  EDIT_COMPANY_DETAILS,
  ANNOUNCEMENT,
  EDIT_PERMANENT_JOB,
  UPGRADE_MEMBERSHIP,
  PRIVACYPOLICY
} from "../../Constants/Router/RouterConstant";
import { Layout } from "antd";
import SideBar from "../../Layouts/AppLayout/SideBar/SideBar";
import { Content } from "antd/es/layout/layout";
import { LoadingOutlined } from "@ant-design/icons";
// import Transaction from "./Transaction/Transaction";
// import Profile from "./Profile/Profile";
// import JobDetails from "./JobDetails/JobDetails";
// import AddJob from "./AddJob/AddJob";
import { useDispatch } from "react-redux";
import { fetchData } from "../../Redux/Slice/PositionSlice";
import { fetchDataForVerification } from "../../Redux/Slice/VerifySlice";
import AboutUs from "./AboutUs/AboutUs";
import TermsAndCondition from "./Terms&Condition/Terms&Condition";
import BlackList from "./BlackList/BlackList";
import Schedular from "./Schedular/Schedular";
import Dispute from "./Dispute/Dispute";
import DisputeDetails from "./DisputeDetails/DisputeDetails";
import ChatSupport from "./ChatSupport/ChatSupport";
import PersonalDetails from "../AuthViews/PersonalDetails/PersonalDetails";
import EditCompanyDetails from "../AppViews/EditCompanyDetails/EditCompanyDetails";
import Announcement from "./Announcement/Announcement";
import EditPermanentJob from "./AddPermanentJob/EditPermanentJobs";
import UpgradeMembership from "./UpgradeMembership/UpgradeMembership";
import PrivacyPolicy from "./Terms&Condition/PrivacyPolicy";
// import AddPermanentJob from "./AddPermanentJob/AddPermanentJob";
// import PermanentJobDetails from "./PermanentJobDetails/PermanentJobDetails";
// import PaymentCard from "./PaymentCard/PaymentCard";
// import AddCard from "./AddCard/AddCard";
// import SelectCard from "./SelectCard/SelectCard";
// import OfficerChat from "./OfficerChat/OfficerChat";

const Dashboard = lazy(() => import("./Dashboard/Dashboard"));
const AdHoc = lazy(() => import("./AdHoc/AdHoc"));
const PermanentJobs = lazy(() => import("./Permanent/PermanentJobs"));
const PermanentJobDetails = lazy(() =>
  import("./PermanentJobDetails/PermanentJobDetails")
);
const JobDetails = lazy(() => import("./JobDetails/JobDetails"));
const AddJob = lazy(() => import("./AddJob/AddJob"));
const SelectCard = lazy(() => import("./SelectCard/SelectCard"));
const OfficerChat = lazy(() => import("./OfficerChat/OfficerChat"));
const Transaction = lazy(() => import("./Transaction/Transaction"));
const Profile = lazy(() => import("./Profile/Profile"));
const PaymentCard = lazy(() => import("./PaymentCard/PaymentCard"));
const AddCard = lazy(() => import("./AddCard/AddCard"));
const AddPermanentJob = lazy(() => import("./AddPermanentJob/AddPermanentJob"));

function Index() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData());
    dispatch(fetchDataForVerification());
  }, []);

  return (
    <>
      <Router>
        <Layout style={{ height: "100vh" }}>
          <SideBar />
          <Layout className="app-layout">
            <Content>
              <Suspense
                fallback={
                  <div className="w-full h-full flex justify-center items-center">
                    <LoadingOutlined />
                  </div>
                }
              >
                <Routes>
                  <Route path={DASHBOARD} element={<Dashboard />} />
                  <Route path={ADHOC} element={<AdHoc />} />
                  <Route path={ADD_JOB} element={<AddJob />} />
                  <Route path={EDIT_JOB} element={<AddJob />} />
                  <Route path={SELECT_CARD} element={<SelectCard />} />
                  <Route path={JOB_DETAIL} element={<JobDetails />} />
                  <Route path={OFFICERS_CHAT} element={<OfficerChat />} />
                  <Route path={EDIT_PROFILE} element={<PersonalDetails/>}/>
                  <Route path={PERMANENT} element={<PermanentJobs />} />
                  
                  <Route
                    path={ADD_PERMANENT_JOB}
                    element={<AddPermanentJob />}
                  />
                  <Route
                    path={EDIT_PERMANENT_JOB}
                    element={<EditPermanentJob />}
                  />
                  <Route
                    path={PERMANENT_JOB_DETAIL}
                    element={<PermanentJobDetails />}
                  />
                  <Route path={TRANSACTION} element={<Transaction />} />
                  <Route path={PROFILE} element={<Profile />} />
                  <Route
                    path={PROFILE + MANAGE_CARD}
                    element={<PaymentCard />}
                  />
                  <Route path={PROFILE + ABOUT} element={<AboutUs />} />
                  <Route
                    path={PROFILE + TERMSANDCONDITION}
                    element={<TermsAndCondition />}
                  />
                  <Route
                    path={PROFILE + PRIVACYPOLICY}
                    element={<PrivacyPolicy />}
                  />
                  <Route path={PROFILE + BLACKLIST} element={<BlackList />} />
                  <Route path={PROFILE + CREDIT_CARD} element={<AddCard />} />
                  <Route path={PROFILE+SCHEDULAR} element={<Schedular/>} />
                  <Route path={PROFILE+ DISPUTE} element={<Dispute/>}/>
                  <Route path={PROFILE+DISPUTE+DISPUTE_DETAILS} element={<DisputeDetails/>}/>
                  <Route path={PROFILE+CHAT_SUPPORT} element={<ChatSupport/>}/>
                  <Route path={PROFILE+ANNOUNCEMENT} element={<Announcement/>} />
                  <Route path={UPGRADE_MEMBERSHIP} element={<UpgradeMembership/>} />
                  <Route path="*" element={<Navigate to={DASHBOARD} />} />
                  <Route path={PROFILE + EDIT_COMPANY_DETAILS} element={<EditCompanyDetails />} />
                </Routes>
              </Suspense>
            </Content>
          </Layout>
        </Layout>
      </Router>
    </>
  );
}

export default Index;
