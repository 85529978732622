import React, { useEffect, useRef, useState } from "react";
// import { REACT_APP_GOOGLE_MAPS_KEY } from "../constants/constants";
const  REACT_APP_GOOGLE_MAPS_KEY = "AIzaSyAWSQ-M2SM7zWTUFlKY53_8m6zRlWC_Zxw"

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const LocationSearch = ({ setLocationData} ) => {
// console.log(value);
  const [query, setQuery] = useState();
  const autoCompleteRef = useRef(null);

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        // types: ["(cities)"],
        componentRestrictions: { country: "SGP" },
      }
    );

    autoComplete.addListener("place_changed", () => {
      handlePlaceSelect(updateQuery);
    });
  };

  const handlePlaceSelect = async (updateQuery) => {
      const addressObject = await autoComplete.getPlace();
    //   console.log(addressObject);
      const query = addressObject.formatted_address;      ;
      
    updateQuery(query);
      const latLng = {
      address:query,
      latitude: addressObject?.geometry?.location?.lat(),
      longitude: addressObject?.geometry?.location?.lng(),
    };
    setLocationData(latLng);
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`,
        () => handleScriptLoad(setQuery, autoCompleteRef)
      
    );
    console.log("searching...");
  }, []);

  return (
    <div >
      {/* <h1>Location</h1> */}
      <input
        ref={autoCompleteRef}
        className="w-full p-3 border-2 rounded border-[#e8e5e5] z-100 "
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Search Places ..."
        value={query}
      />
    </div>
  );
};

export default LocationSearch;
