import {
  ADHOC,
  DASHBOARD,
  PERMANENT,
  PROFILE,
  TRANSACTION,
} from "../Router/RouterConstant";

export const ShowSidebar = [
  DASHBOARD,
  ADHOC,
  PERMANENT,
  TRANSACTION,
  PROFILE,
];
