import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    tempCompanyData: [],
}

const tempCompanyDataSlice = createSlice({
    name: "tempCompanyData",
    initialState,
    reducers: {
        setTempCompanyData: (state, action) => {
            return {
                ...state,
                tempCompanyData: action.payload,
            }
        }
    }
})

export const { setTempCompanyData } = tempCompanyDataSlice.actions;
export default tempCompanyDataSlice.reducer;