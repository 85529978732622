import { createSlice } from "@reduxjs/toolkit";

const initialState = 0;

const jobTabSlice = createSlice({
  name: "jobTab",
  initialState,
  reducers: {
    setJobTab: (state, action) => {
      return action.payload;
    },
  },
});


export const { setJobTab } = jobTabSlice.actions;
export default jobTabSlice.reducer;