import {
    Button,
    Checkbox,
    Form,
    Input,
    Select,
    TimePicker,
    message,
  } from "antd";
  import React, { useEffect, useState } from "react";
  import Navbar from "../../../Components/Navbar/Navbar";
  import LabelForInput from "../../../Components/Inputs/LabelForInput/LabelForInput";
  import { useSelector } from "react-redux";
  import { PERMANENT } from "../../../Constants/Router/RouterConstant";
  import axios from "axios";
import { useNavigate,useLocation } from "react-router-dom";
  import LocationSearch from "../../../Components/LocationSearch/LocationSearch";
  import LocationSearch2 from "../../../Components/LocationSearch/LocationSearch2";
  
  function EditPermanentJob() {
    const [JobType, setJobType] = useState();
    const [isAvailable, setIsAvailable] = useState({
      is_cpf_applicable: false,
      certification_required: false,
    });
    const [form] = Form.useForm();
    const { positionSlice, verifySlice } = useSelector((state) => state);
    const navigate = useNavigate();
    const [InterviewLocationData, setInterviewLocationData] = useState(null);
      const [LocationData, setLocationData] = useState(null);
      const location = useLocation();
    const query = new URLSearchParams(location.search);
      const job_token = query.get("job_token");
      //   console.log(job_token);
      const [locationValue, setlocationValue] = useState("");
      
      useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.post("sa_edit_permanent_job",{
                    job_token:job_token
                });
                const data = res.data.data;
                console.log(data);
                form.setFieldsValue(data); 
            } catch (error) {
              console.log("Cannot get data");
            }
          };
      
          fetchData();
          
      },[])
    const onFinish = async (value) => {
      const data = {
        title: value.title,
        job_type: JobType,
        // job_location: LocationData.address,
        // site_latitude: LocationData.latitude,
        // site_longitude: LocationData.longitude,
        unit_number: value.unit_number,
        interview_location: InterviewLocationData.address,
        interview_unit_number: value.interview_unit_number,
        is_vaccination_compulsory: 0,
        position: value.position,
        vacancy: value.vacancy,
        contract_duration:
          value.contract_duration + " " + value.contract_duration_type,
        basic_salary: value.basic_salary,
        allowance_per_month: value.allowance_per_month,
        shift_type: value.shift_type,
        working_hours: value.working_hours,
        job_description: value.job_description,
        is_cpf_applicable: isAvailable.is_cpf_applicable ? 1 : 0,
        certification_required: isAvailable.certification_required ? 1 : 0,
        other_requirements: value.other_requirements,
        contact_person: value.contact_person,
        additional_payment_time: value.additional_payment_time || {
          hours: 0,
          minutes: 45,
          seconds: 0,
        },
        Locality:"",
        interview_longitude: InterviewLocationData.longitude,
        interview_latitude: InterviewLocationData.latitude,
        draft: value.draft,
        company_id: verifySlice?.data?.response?.user?.company_id,
      };
    //   console.log(data);
      try {
        const res = await axios.post("/sa_update_permanent_job", data);
        if (res.data.status) {
          message.success(res.data.message);
            if (value.draft) {
              
          } else {
            navigate(PERMANENT);
          }
        } else {
        }
      } catch (err) {
        console.log(err);
        message.error("Something went wrong");
      }
    };
    return (
      <>
        <Navbar text="EDIT PERMANENT JOB" />
        <div className="flex justify-center items-center pt-10 w-80 m-auto flex-col gap-5 pb-14">
          <Form form={form} onFinish={onFinish}>
            <div className="w-full">
              <LabelForInput text="Enter Job Title *" />
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Job Title",
                  },
                ]}
              >
                <Input placeholder="Enter Job Title" className="h-12" />
              </Form.Item>
            </div>
            <div className="w-full">
              <LabelForInput text="Job Type *" />
                        <Form.Item
                            name="job_type"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Job Type",
                  },
                ]}
              >
                <Select
                  value={JobType}
                  onChange={(e) => {
                    form.resetFields([
                      "contract_duration",
                      "contract_duration_type",
                    ]);
                    setJobType(e);
                  }}
                  placeholder="Enter Job Type"
                  className="h-12"
                >
                  <Select.Option value={2}>Permanent</Select.Option>
                  <Select.Option value={1}>Contract</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="mb-5">
              <div>Site Location *</div>
                        <LocationSearch setLocationData={setLocationData}  />
            </div>
            <div>
              <LabelForInput text="Site Unit/Block Number *" />
              <Form.Item
                name="unit_number"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Site Unit/Block Number",
                  },
                ]}
              >
                <Input placeholder="Site Unit/Block Number" className="h-12" />
              </Form.Item>
            </div>
            <div className="mb-5">
              <div>Interview Location *</div>
              <LocationSearch2 setInterviewLocationData={setInterviewLocationData}/>
            </div>
            <div>
              <LabelForInput text="Interview Unit/Block Number *" />
              <Form.Item
                name="interview_unit_number"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Interview Unit/Block Number",
                  },
                ]}
              >
                <Input
                  placeholder="Interview Unit/Block Number"
                  className="h-12"
                />
              </Form.Item>
            </div>
            <div className="w-full flex justify-between">
              <div className="w-[49%]">
                <LabelForInput text={"Officer Rank"} />
                <Form.Item
                  name="position"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Position",
                    },
                  ]}
                >
                  <Select placeholder="Select" className="h-12">
                    {positionSlice?.data?.response?.map((item, index) => (
                      <Select.Option value={item.value} key={index}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="w-[49%]">
                <LabelForInput text={"Vacancy"} />
                <Form.Item
                  name="vacancy"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Vacancy",
                    },
                  ]}
                >
                  <Input placeholder="Enter Vacancy" className="h-12" />
                </Form.Item>
              </div>
            </div>
            {JobType === 1 && (
              <div className="w-full flex justify-between">
                <div className="w-[49%]">
                  <LabelForInput text="Contract Duration" />
                  <Form.Item
                    name="contract_duration"
                    rules={[
                      {
                        required: JobType,
                        message: "Please Enter Contract Duration",
                      },
                      {
                        pattern: /^[0-9]*$/,
                      },
                    ]}
                  >
                    <Input placeholder="Contract Duration" className="h-12" />
                  </Form.Item>
                </div>
                <div className="w-[49%]">
                  <LabelForInput text="Contract Duration" />
                  <Form.Item
                    name="contract_duration_type"
                    rules={[
                      {
                        required: JobType,
                        message: "Please Enter Contract Duration Type",
                      },
                    ]}
                  >
                    <Select placeholder="Select" className="h-12">
                      <Select.Option value={"Days"}>Days</Select.Option>
                      <Select.Option value={"Months"}>Months</Select.Option>
                      <Select.Option value={"Years"}>Years</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            )}
  
            <div>
              <LabelForInput text="Basic Salary Per Month *" />
              <Form.Item
                name="basic_salary"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Basic Salary",
                  },
                  {
                    pattern: /^[0-9]*$/,
                  },
                ]}
              >
                <Input
                  placeholder="Basic Salary Per Month in SGD"
                  className="h-12"
                />
              </Form.Item>
            </div>
  
            <div>
              <LabelForInput text="Allowance Per Month in SGD*" />
              <Form.Item
                name="allowance_per_month"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Allowance Per Month in SGD",
                  },
                ]}
              >
                <Input
                  placeholder="Allowance Per Month in SGD"
                  className="h-12"
                />
              </Form.Item>
            </div>
  
            <div className="w-full flex justify-between">
              <div className="w-[49%]">
                <LabelForInput text={"Shift Type"} />
                <Form.Item
                  name="shift_type"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Position",
                    },
                  ]}
                >
                  <Select placeholder="Select" className="h-12">
                    <Select.Option value={1}>Day</Select.Option>
                    <Select.Option value={2}>Night</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="w-[49%]">
                <LabelForInput text={"Working Hours"} />
                <Form.Item
                  name="working_hours"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Working Hours",
                    },
                    {
                      pattern: /^[0-9]*$/,
                    },
                  ]}
                >
                  <Input placeholder="Enter Working Hours" className="h-12" />
                </Form.Item>
              </div>
            </div>
            <div className="w-full">
              <LabelForInput text="Additional Time For Payment" />
              <Form.Item
                name="additional_payment_time"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Additional Time For Payment",
                  },
                ]}
              >
                <TimePicker
                  format={"HH:mm"}
                  placeholder="Interview Location"
                  className="h-12 w-full"
                />
              </Form.Item>
            </div>
            <div className="w-full">
              <LabelForInput text="Job Description" />
              <Form.Item
                name="job_description"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Job Description",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter Job Description"
                  className="h-96"
                />
              </Form.Item>
            </div>
            <Form.Item>
              <Checkbox
                onChange={(e) => {
                  setIsAvailable({
                    ...isAvailable,
                    certification_required: e.target.checked,
                  });
                }}
              >
                Certification Required
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Checkbox
                onChange={(e) => {
                  setIsAvailable({
                    ...isAvailable,
                    is_cpf_applicable: e.target.checked,
                  });
                }}
              >
                CPF Applicable
              </Checkbox>
            </Form.Item>
            <div className="w-full">
              <LabelForInput text="Other Requirements" />
              <Form.Item
                name="other_requirements"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Other Requirements",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Other Requirements"
                  className="h-96"
                />
              </Form.Item>
            </div>
            <div className="w-full">
              <LabelForInput text="Contact Person" />
              <Form.Item
                name="contact_person"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Contact Person",
                  },
                ]}
              >
                <Input placeholder="Enter Contact Person" className="h-12" />
              </Form.Item>
            </div>
  
            <div className="w-full">
              <Form.Item name={"draft"}>
                <Button
                  onClick={() => {
                    form.setFieldValue("draft", false);
                  }}
                  htmlType="submit"
                  className="mb-2 font-bold text-lg text-white w-80 h-12 rounded-md bg-secondary"
                >
                  Next
                </Button>
                <Button
                  className="font-bold text-lg w-80 h-12 rounded-md "
                  onClick={() => {
                    form.setFieldValue("draft", true);
                  }}
                  htmlType="submit"
                >
                  Draft
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </>
    );
  }
  
  export default EditPermanentJob;
  